<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #left> </template>

      <template #right>
        <!-- <Button icon="pi pi-cog" class="p-button-basic p-ml-2" /> -->
        <Button
          icon="pi pi-download"
          class="p-button-help"
          @click="exportCSV($event)"
          v-tooltip="'طباعه'"
        />
        <!-- <Button icon="pi pi-search" class="p-ml-2" />
        <Button icon="pi pi-times" class="p-button-danger" /> -->
      </template>
    </Toolbar>

    <DataTable
      ref="dt"
      :value="list"
      :paginator="true"
      class="p-datatable-customers table table-striped"
      :rows="10"
      dataKey="id"
      :rowHover="true"
      :selection.sync="selectedItems"
      :expandedRows.sync="expandedRows"
      :loading="loading"
      :filters="filters"
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      :rowsPerPageOptions="[10, 25, 50, 100, 500, 1000, 10000]"
      currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
    >
      <template #header>
        <div class="table-header">
          قائمه توزيع التركيبات
        </div>
      </template>
      <template #empty>
        لا يوجد بيانات
      </template>
      <template #loading>
        يتم تحميل البيانات. يرجي الانتظار...
      </template>
      <!-- <Column selectionMode="multiple" field="id" headerStyle="width: 3em" /> -->

      <!-- <Column field="id" header="رقم العقد" :sortable="true" /> -->

      <Column
        field="contractsId.clientsId.code"
        header="كود العميل"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="number"
            v-model="filters['contractsId.clientsId.code']"
            class="p-column-filter"
            placeholder="بحث بكود العميل"
          />
        </template>
      </Column>
      <Column
        field="contractsId.clientsId.name"
        filterMatchMode="contains"
        header="اسم العميل"
        :sortable="true"
      >
        <template #filter>
          <InputText
            v-model="filters['contractsId.clientsId.name']"
            class="p-column-filter"
            placeholder="بحث بأسم العميل"
          />
        </template>
      </Column>

      <Column
        field="contractsId.branchesId.name"
        header="اسم الفرع"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            v-model="filters['contractsId.branchesId.name']"
            class="p-column-filter"
            placeholder="بحث بأسم الفرع"
          />
        </template>
      </Column>

      <Column
        field="contractsId.date"
        header="تاريخ العقد"
        filterMatchMode="contains"
        :sortable="true"
      >
        <template #filter>
          <InputText
            type="date"
            v-model="filters['contractsId.date']"
            class="p-column-filter"
            placeholder="بحث بتاريخ العقد"
          />
        </template>
      </Column>

      <Column
        field="id"
        header="الحاله"
        :sortable="true"
        bodyStyle="text-align: center; overflow: visible"
      >
        <template #body="slotProps">
          {{
            slotProps.data.contractsId &&
            slotProps.data.contractsId.installationsLogs &&
            slotProps.data.contractsId.installationsLogs.length > 0
              ? $getStatus(
                  slotProps.data.contractsId.installationsLogs[0].status,
                )
              : $getStatus(slotProps.data.status)
          }}
        </template>
      </Column>

      <Column :expander="true" headerStyle="width: 3rem" />

      <template #expansion="slotProps">
        <div class="orders-subtable">
          <DataTable
            :value="[slotProps.data.contractsId]"
            class="p-datatable-customers table table-striped"
          >
            <template #empty>
              لا يوجد بيانات
            </template>

            <Column field="deliveryDate" header="اقصى تاريخ تسليم" />
            <Column field="address" header="الموقع" />
            <Column field="note" header="ملاحظات" />
          </DataTable>
          <DataTable
            :value="[slotProps.data]"
            v-if="slotProps.data.backDeliveryNote"
            class="p-datatable-customers table table-striped"
          >
            <template #empty>
              لا يوجد بيانات
            </template>

            <Column field="backDeliveryNote" header="ملاحظات السكيرتاريه" />
          </DataTable>
          <DataTable
            :value="[slotProps.data.materialsId]"
            class="p-datatable-customers table table-striped"
          >
            <template #header>
              بيانات المخازن
            </template>
            <template #empty>
              لا يوجد بيانات
            </template>

            <Column
              field="id"
              header="تم الطلب"
              bodyStyle="text-align: center; overflow: visible"
            >
              <template #body="slotProps">
                <InputSwitch v-model="slotProps.data.orderDone" disabled />
                {{ slotProps.data.orderDoneDate }}
              </template>
            </Column>
            <Column
              field="id"
              header="تم التجهيز"
              bodyStyle="text-align: center; overflow: visible"
            >
              <template #body="slotProps">
                <InputSwitch v-model="slotProps.data.editDone" disabled />
                {{ slotProps.data.editDoneDate }}
              </template>
            </Column>
            <Column
              field="id"
              header="تم الشحن"
              bodyStyle="text-align: center; overflow: visible"
            >
              <template #body="slotProps">
                <InputSwitch v-model="slotProps.data.chargedDone" disabled />
                {{ slotProps.data.chargedDoneDate }}
              </template>
            </Column>
            <!-- <Column field="updatedAt" header="اخر تاريخ تعديل">
              <template #body="slotProps">
                {{ format_date(slotProps.data.updatedAt) }}
              </template>
            </Column> -->

            <Column field="note" header="ملاحظات المخزن" />
          </DataTable>
          <DataTable
            :value="slotProps.data.contractsId.productsList"
            class="p-datatable-customers table table-striped"
            :rows="10"
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            :rowsPerPageOptions="[10, 25, 50, 100, 500, 1000, 10000]"
            currentPageReportTemplate="عرض {first} الى {last} من {totalRecords} مدخلات"
          >
            <template #header>
              قائمه المنتجات والخدمات
            </template>
            <template #empty>
              لا يوجد بيانات
            </template>

            <Column field="productsId.name" header="المنتج" />
            <Column field="itemsId.name" header="الخدمه" />

            <Column
              field="id"
              header="توزيع"
              bodyStyle="text-align: center; overflow: visible"
            >
              <template #body="slotProps2">
                <p v-if="slotProps2.data.usersId">
                  {{ slotProps2.data.usersId.name }}
                </p>
                <Button
                  type="button"
                  v-tooltip="'توزيع'"
                  v-else
                  @click="
                    toDelivery(
                      slotProps2.data,
                      slotProps.data.contractsId.id,
                      slotProps.data.id,
                    )
                  "
                  icon="pi pi-reply"
                  class="p-button-info p-ml-2  p-button-rounded"
                ></Button>
              </template>
            </Column>
          </DataTable>
        </div>
      </template>
    </DataTable>

    <Dialog
      header="التوزيع"
      :visible.sync="displayBasicToDelivery"
      :style="{ width: '80vw' }"
    >
      <div class="p-m-0">
        <div class="form-card ">
          <div class="mb-3 ">
            <label for="usersId" class="form-label">
              الموظف المسئول
            </label>

            <select
              class="form-select form-control"
              id="usersId"
              name="usersId"
              v-model.number="delivery.usersId"
            >
              <option
                v-for="item of usersList"
                :key="item.id"
                :value="item.id"
                >{{ item.name }}</option
              >
            </select>
          </div>
        </div>
      </div>
      <template #footer>
        <Button
          label="حفظ"
          icon="pi pi-check"
          @click="toAddDelivery"
          autofocus
        />
        <Button
          label="الغاء"
          icon="pi pi-times"
          @click="displayBasicToDelivery = false"
          class="p-button-text"
        />
      </template>
    </Dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      filters: {},

      list: [],
      selectedItems: [],
      displayBasic: false,
      expandedRows: [],
      loading: true,

      delivery: {
        usersId: null,
      },
      usersList: [],
      productsListId: null,
      installationsId: null,
      displayBasicToDelivery: false,
    };
  },
  methods: {
    toDelivery(data, id, installationsId) {
      this.productsListId = data.id;

      this.$http
        .post(`productsList/count`, {
          usersId: null,
          contractsId: id,
        })
        .then((res) => {
          if (res.data == 1) {
            this.installationsId = installationsId;
          } else {
            this.installationsId = null;
          }
        });

      this.displayBasicToDelivery = true;
      this.$http.get(`items/${data.itemsId.id}`).then((res) => {
        this.usersList = res.data.users;
      });
      this.delivery = {
        usersId: null,
      };
    },
    toAddDelivery() {
      this.displayBasicToDelivery = false;
      if (this.installationsId) {
        this.$http
          .put(`installations/${this.installationsId}`, {
            status: 'UnderOperation',
          })
          .then(() => {});
      }

      this.$http
        .put(`productsList/` + this.productsListId, {
          ...this.delivery,
          distributionsDate: new Date(),
        })
        .then(
          () => {
            this.$toast.add({
              severity: 'success',
              summary: 'تم بنجاح',
              detail: 'تم التعديل بنجاح',
              life: 3000,
            });
            this.getData();
          },
          (err) => {
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
    },

    getData() {
      if (this.$checkRoles('distributionsVeiwAll')) {
        this.$http.get(`installations/getAllDistributions`).then(
          (response) => {
            this.loading = false;
            this.list = response.data;
          },
          (err) => {
            this.loading = false;
            this.$toast.add({
              severity: 'error',
              summary: 'هناك خطأ',
              detail: err.response.data.message,
              life: 3000,
            });
          },
        );
      } else {
        const user = JSON.parse(localStorage.admin);

        this.$http
          .get(
            `installations/getAllDistributionsBySectionsId/${user.sectionsId}`,
          )
          .then(
            (response) => {
              this.loading = false;
              this.list = response.data;
            },
            (err) => {
              this.loading = false;
              this.$toast.add({
                severity: 'error',
                summary: 'هناك خطأ',
                detail: err.response.data.message,
                life: 3000,
              });
            },
          );
      }
    },

    exportCSV() {
      this.$refs.dt.exportCSV();
    },
  },
  created() {
    if (!this.$checkRoles('distributionsVeiw')) {
      this.$router.push('/admin/');
    }
    this.getData();
  },
};
</script>
